<template>
<body>
    <section id="home" class="hero">
        <h1>Masih Maintenance</h1>
    </section>
</body>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
}

header {
    background-color: #333;
    color: #fff;
    padding: 1em 0;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
}

nav .logo {
    font-size: 1.5em;
    font-weight: bold;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 1em;
}

nav ul li {
    margin: 0;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

.hero {
    text-align: center;
    padding: 4em 2em;
    background: #f4f4f4;
}

.hero h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
}

.hero p {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.hero .cta-button {
    padding: 0.5em 2em;
    background: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.features, .testimonials {
    padding: 2em;
    background: #fff;
    text-align: center;
}

.features h2, .testimonials h2 {
    font-size: 2em;
    margin-bottom: 1em;
}

.feature-item, .testimonial-item {
    margin-bottom: 1em;
}

footer {
    background: #333;
    color: #fff;
    padding: 2em;
    text-align: center;
}

footer form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

footer form input, footer form textarea {
    width: 80%;
    padding: 0.5em;
}

footer form button {
    padding: 0.5em 2em;
    background: #f4f4f4;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
</style>
