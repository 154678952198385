<template>
    <div>
      <h1>About</h1>
      <p>This is the about page.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  }
  </script>
  
  <style scoped>
  h1 {
    color: #42b983;
  }
  </style>
  